import React from 'react';
import { StyleSheet, View } from 'react-native';
import {  useDispatch } from 'react-redux'
import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList } from '@react-navigation/drawer';
import AsyncStorage from '@react-native-async-storage/async-storage';
import HomeStack from './HomeStack';
import {GamesScreen} from '../Screens/Games';
import LeagueBottomTabBar from './LeagueBottomTabsBar';
import LeagueLoadingStack from './LeagueLoadingStack';
import { lightGrey, primaryColor, thirdColor, transparent } from '../Styles/colors';
import BtxButton from '../Components/BtxButton';
import { strings } from '../locales/i18n';
import { sizeM } from '../Styles/text';
import RulesStack from './RulesStack';
import {ProfileStack} from './ProfileStack';
import { Paragraph, Title4 } from '../Components/Texts';
import { NbaGamesStack } from './NbaGamesStack';

const Drawer = createDrawerNavigator();
export default function DrawerApp({ props }) {
  const dispatch = useDispatch();

  const signOut = async() => {
    await AsyncStorage.clear();
    const action = { type: 'AUTH_LOGOUT' };
    dispatch(action);
  };

  return (
    <Drawer.Navigator
      initialRouteName='HomeStack'
      screenOptions={{
        drawerPosition: 'left',
        headerShown: false,
      }}
      drawerContent={props => (
          <View style={styles.drawerContainer}>
            <View style={styles.tabsContainer}>
              <DrawerContentScrollView {...props}>
                <DrawerItemList activeTintColor={primaryColor} activeBackgroundColor={transparent} {...props} />
              </DrawerContentScrollView>
            </View>
            <View style={ styles.footer }>
              <Title4>Un bug, une suggestion ou une simple envie de nous contacter ? Envoyez-nous un email !</Title4>
              <Paragraph style={styles.mailText}>contact.basketix@gmail.com</Paragraph>
              <View style={styles.buttonContainer}>
                <BtxButton
                  title='Se deconnecter'
                  onPress={ signOut }
                  color={thirdColor}
                  backgroundColor={transparent}
                  padding={10}
                />
              </View>
            </View>
          </View>
        )
      }
    >
      <Drawer.Screen
        name='NbaGames'
        component={NbaGamesStack}
        options={{ drawerLabel: strings('navigation.NBAGames')}}
      />
      <Drawer.Screen
        name='HomeStack'
        component={HomeStack}
        options={{
          drawerItemStyle: {
            display: "none",
          }
        }}
      />
      <Drawer.Screen
        name='Rules'
        component={RulesStack}
        options={{ drawerLabel: strings('navigation.Rules')}}
      />
      <Drawer.Screen
        name='Profile'
        component={ProfileStack}
        options={{ drawerLabel: strings('navigation.Profile') }}
      />
      <Drawer.Screen
        name={strings('navigation.LeagueBottomTabBar')}
        component={LeagueBottomTabBar}
        options={{
          drawerItemStyle: {
            display: "none",
          }
        }}
      />
      <Drawer.Screen
        name='LeagueLoadingStack'
        component={LeagueLoadingStack}
        options={{
          drawerItemStyle: {
            display: "none",
          }
        }}
      />
    </Drawer.Navigator>
  );
}

const styles = StyleSheet.create({
  drawerContainer: {
    flex: 1,
    alignContent: 'flex-end',
  },
  tabsContainer: {
    flex: 1,
  },
  footer: {
    alignSelf: 'flex-end',
    flexDirection: 'column',
    borderTopWidth: 2,
    borderColor: lightGrey,
    paddingVertical: 20,
    marginTop: 10,
    marginHorizontal: 10,
  },
  mailText: {
    marginTop: 4,
    fontSize: sizeM,
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 12,
  }
})
